import { useState } from 'react';
import './Login.scss';
import { useLocation, useNavigate } from 'react-router';
import Account from '../../api/controllers/Account/Account';
import useToken from '../../hooks/useToken/useToken';
import { ReactComponent as FreightsoftLogo } from '../../assets/Freightsoft-logo.svg';
import Button from '../../common/Button/Button';
import Input from '../../common/Input/Input';
import Error from '../../common/Error/Error';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  const [error, setError] = useState();
  const [formDisabled, setFormDisabled] = useState(false);

  const { setToken } = useToken();
  const navigate = useNavigate();
  const location = useLocation();

  const prevURL = location.state;

  const login = e => {
    setFormDisabled(true);
    e.preventDefault();
    e.stopPropagation();
    Account.logIn(email, password)
      .then(({ token }) => {
        setToken(token);
        setTimeout(() => {
          navigate(prevURL ? prevURL : '/');
        }, 100);
      })
      .catch(e => {
        setError(e.error);
        setFormDisabled(false);
      });
  };

  return (
    <div className="login">
      <div className="logo">
        <FreightsoftLogo className="logoImage" />
      </div>
      <div className="form">
        <form className={`content ${formDisabled && 'disableForm'}`} onSubmit={login}>
          {error && (
            <div className="error_container">
              <Error errorMessage={error} />
            </div>
          )}
          <h1 className="title">Log in</h1>
          <p className="subtitle">Use the credentials we emailed you to access.</p>

          <Input
            label="Email Address"
            autofocus={true}
            value={email}
            setValue={setEmail}
            disabled={formDisabled}
          />
          <Input
            label="Password"
            type="password"
            value={password}
            setValue={setPassword}
            disabled={formDisabled}
          />
          <div className="forgotlink">
            <p>Forgot your password?</p>
            <a href="mailto:FSportal@ital-logistics.com?subject=FREIGHTsoft: Forgotten password">
              Send us an email
            </a>
          </div>
          <Button
            type="submit"
            buttonType="primary_button"
            label="Log In"
            disabled={formDisabled}
          />
        </form>
      </div>
    </div>
  );
};

export default Login;
