import './Button.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faChevronLeft } from '@fortawesome/free-solid-svg-icons';

// General Button Component
const Button = ({ buttonType, label, onClick, disabled }) => {
  return (
    <div className={`button_component ${buttonType}`}>
      {buttonType.includes('back') && buttonType.includes('secondary_button') && (
        <FontAwesomeIcon
          className={`chevron ${disabled ? 'disabled' : ''}`}
          icon={faChevronLeft}
          data-testid="back_icon"
        />
      )}
      <button
        className={buttonType}
        disabled={disabled}
        data-testid={`button-${label}`}
        onClick={onClick}
      >
        {label}
      </button>
    </div>
  );
};

Button.propTypes = {
  buttonType: PropTypes.string,
  label: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Button;
