import PropTypes from 'prop-types';
import { faSortDown, faSortUp } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './TablePresenter.scss';
import { ReactComponent as InfoIcon } from '../../assets/info-icon.svg';
import Button from '../Button/Button';
import LoadingSpinner from '../LoadingSpinner/LoadingSpinner';

const TablePresenter = ({
  headers,
  rows,
  onDrawRow,
  sort,
  onSort,
  pageElements,
  pageText,
  noEntity,
  loading,
}) => {
  const headerCells = headers.map((header, i) => (
    <th
      key={i}
      onClick={header.sortingAttribute ? () => onSort(header) : null}
      className={header.className || null}
    >
      {header.text}
      {header.sortingAttribute && (
        <>
          <FontAwesomeIcon
            className={`sort_icon_up ${
              sort && sort.sort == header.sortingAttribute && sort.order === 'desc'
                ? 'sorted_header'
                : ''
            }`}
            icon={faSortUp}
            data-testid={`sort_icon_up_${header.className}`}
          />
          <FontAwesomeIcon
            className={`sort_icon_down ${
              sort && sort.sort == header.sortingAttribute && sort.order === 'asc'
                ? 'sorted_header'
                : ''
            }`}
            icon={faSortDown}
            data-testid={`sort_icon_down_${header.className}`}
          />
        </>
      )}
    </th>
  ));

  const tableRows = () => rows && rows.map((row, i) => onDrawRow(row, i));

  return (
    <div className="table">
      <div className="table_container">
        <table>
          <thead>
            <tr className="header">{headerCells}</tr>
          </thead>
          {!loading && <tbody>{tableRows()}</tbody>}
        </table>
        {loading && (
          <div className="no_entity">
            <div className="info_container">
              <LoadingSpinner />
            </div>
          </div>
        )}
        {rows.length == 0 && !noEntity && !loading && (
          <div className="no_entity">
            <div className="info_container">
              <InfoIcon className="info_icon" />
              <h2>There are no jobs that match the current filter</h2>
              <p>Use the status filter to see more options</p>
            </div>
          </div>
        )}
        {noEntity && !loading && (
          <div className="no_entity">
            <div className="info_container">
              <InfoIcon className="info_icon" />
              <h2>Your user account is not associated with an entity</h2>
              <p>Please contact us to get your account fully set up</p>
              <div className="button_container">
                <Button
                  label="Contact us"
                  disabled={false}
                  onClick={() =>
                    window.open(
                      'mailto:FSportal@ital-logistics.com?subject=FREIGHTsoft: Account not linked to entity',
                    )
                  }
                  buttonType="primary_button"
                />
              </div>
            </div>
          </div>
        )}
      </div>
      {rows.length > 0 && (
        <div className="footer">
          <div className="table_bottom">
            <div className="page_info">{pageText}</div>
            <div className="page_numbers">{pageElements()}</div>
          </div>
        </div>
      )}
    </div>
  );
};

TablePresenter.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.object),
  rows: PropTypes.arrayOf(PropTypes.object),
  onDrawRow: PropTypes.func,
  sort: PropTypes.obj,
  onSort: PropTypes.func,
  pageElements: PropTypes.func,
  pageText: PropTypes.string,
  noEntity: PropTypes.bool,
  loading: PropTypes.bool,
};

export default TablePresenter;
