import PropTypes from 'prop-types';
import { faCaretDown, faCaretUp, faCheck } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './Details.scss';

const Details = ({ options, specialInstructions }) => {
  const {
    temperatureMin,
    temperatureMax,
    insurance,
    cashOnDeliveryValue,
    cashOnDeliveryReceived,
    cashOnDeliveryDescription,
  } = specialInstructions;

  const optionsEmpty = () => {
    const arr = options.map(option => {
      return option.bool;
    });
    if (!arr.includes(true)) {
      return true;
    }
    return false;
  };

  if (
    optionsEmpty() &&
    cashOnDeliveryValue == '-' &&
    temperatureMin == '-' &&
    temperatureMax == '-' &&
    insurance == '-'
  ) {
    return (
      <div className="no_schedule">
        <em>No special instructions found</em>
      </div>
    );
  }

  return (
    <div className="details_container">
      {!optionsEmpty() && (
        <div className="container">
          <p className="heading">Consignment Options</p>
          <div className="consignment_options_container">
            {options.map((option, index) => {
              return (
                option.bool && (
                  <div className="option_row" key={index}>
                    <FontAwesomeIcon
                      icon={faCheck}
                      className={`icon`}
                      data-testid={`${index}_tick`}
                    />
                    <p className={'label'}>{option.text}</p>
                  </div>
                )
              );
            })}
          </div>
        </div>
      )}
      {(temperatureMin != '-' || temperatureMax != '-' || insurance != '-') && (
        <div className="insurance_temperature_container">
          {(temperatureMin != '-' || temperatureMax != '-') && (
            <div className="container">
              <p className="heading">Temperature Controlled</p>
              <div className="temperature_info">
                {temperatureMin != '-' && (
                  <div className="temperature_row">
                    <p className="label temperature_label">MIN. TEMPERATURE</p>
                    <div className="temperature">
                      <FontAwesomeIcon className="cold" icon={faCaretDown} />
                      <p className="info bold">{temperatureMin}°C</p>
                    </div>
                  </div>
                )}
                {temperatureMax != '-' && (
                  <div className="temperature_row">
                    <p className="label temperature_label">MAX. TEMPERATURE</p>
                    <div className="temperature">
                      <FontAwesomeIcon className="hot" icon={faCaretUp} />
                      <p className="info bold">{temperatureMax}°C</p>
                    </div>
                  </div>
                )}
              </div>
            </div>
          )}
          {insurance != '-' && (
            <div className="container">
              <p className="heading">Insurance</p>
              <div className="insurance_info">
                <p className="label">VALUE</p>
                <p className="info bold">{insurance}</p>
              </div>
            </div>
          )}
        </div>
      )}
      {cashOnDeliveryValue != '-' && (
        <div className="container">
          <p className="heading">Cash On Delivery</p>
          <div className="cash_on_delivery_info">
            <div className="info_section">
              <p className="label">VALUE</p>
              <p className="info bold">{cashOnDeliveryValue}</p>
            </div>
            <div className="info_section">
              <p className="label">RECEIVED</p>
              <p className={`info bold ${cashOnDeliveryReceived}`}>{cashOnDeliveryReceived}</p>
            </div>
          </div>
          <div className="cash_on_delivery_description">
            <p className="label">INFO</p>
            <p className="info">
              {cashOnDeliveryDescription.charAt(0) +
                cashOnDeliveryDescription.substring(1).toLowerCase()}
            </p>
          </div>
        </div>
      )}
    </div>
  );
};
Details.propTypes = {
  options: PropTypes.array,
  specialInstructions: PropTypes.object,
};

export default Details;
