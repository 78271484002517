import { faAngleRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import PropTypes from 'prop-types';
import './PageNumbers.scss';

const PageNumbers = ({ currentPage, setCurrentPage, pageTotal }) => {
  const numberElements = [];
  const pushToArr = num => {
    numberElements.push(
      <button
        key={num}
        className={num + 1 === currentPage ? 'page-num current' : 'page-num'}
        onClick={() => setCurrentPage(num + 1)}
      >
        {num + 1}
      </button>,
    );
  };
  for (let i = 0; i < pageTotal; i++) {
    // First / last page or page diff is less than 5
    if (i === 0 || i === pageTotal - 1 || Math.abs(i + 1 - currentPage) < 5) {
      pushToArr(i);
      continue;
    }
    // If page difference is equal to 5 return a filler ... component
    if (Math.abs(i + 1 - currentPage) === 5)
      numberElements.push(
        <button key={i} className={'filler'}>
          ...
        </button>,
      );
  }
  if (pageTotal > 1) {
    numberElements.push(
      <button
        key={'next'}
        className={'next'}
        disabled={currentPage == pageTotal}
        onClick={() => setCurrentPage(currentPage + 1)}
      >
        <FontAwesomeIcon data-testid="next_icon" icon={faAngleRight} />
      </button>,
    );
  }

  return numberElements;
};

PageNumbers.propTypes = {
  currentPage: PropTypes.number,
  setCurrentPage: PropTypes.func,
  pageTotal: PropTypes.number,
};

export default PageNumbers;
