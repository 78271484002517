import './Error.scss';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircleExclamation } from '@fortawesome/free-solid-svg-icons';

// General Error Component
const Error = ({ errorMessage, colour, errorType }) => {
  return (
    <div className={`error ${colour}`} data-testid="error_message">
      <FontAwesomeIcon className="symbol" icon={faCircleExclamation} />
      {errorType == 'cancelled' ? (
        <p className="error_message" data-testid="cancel_error">
          This job has been cancelled. <span className="bold">Reason:</span> {errorMessage}. If you
          want to find out more{' '}
          <a
            className="underline bold"
            href="mailto:FSportal@ital-logistics.com?subject=FREIGHTsoft: Cancelled Job More Info"
          >
            contact us
          </a>
        </p>
      ) : (
        <p className="error_message">{errorMessage}</p>
      )}
    </div>
  );
};

Error.propTypes = {
  errorMessage: PropTypes.string,
  colour: PropTypes.string,
  errorType: PropTypes.string,
};

export default Error;
