import apiCall from '../../apiCall/apiCall';
import buildUrlSearchParams from '../../apiCall/buildUrlSearchParams/buildUrlSearchParams';
// This class handles any PI calls related to attached files.
class Attachment {
  static all(data) {
    const params = buildUrlSearchParams(data);
    return apiCall('GET', 'api/files/items', params);
  }

  // Files must be fetched as raw binary data, so they work a bit differently:
  static download(id) {
    return new Promise((resolve, reject) => {
      const xhr = new XMLHttpRequest();
      xhr.responseType = 'blob';

      xhr.open('GET', `${process.env.REACT_APP_API_SERVER}/api/files/items/${id}/download`);
      const jwt = localStorage.getItem('token');
      if (jwt) {
        xhr.setRequestHeader('Authorization', `Bearer ${jwt}`);
      }
      xhr.send();

      xhr.onload = () => {
        const okay = xhr.status >= 200 && xhr.status < 300;
        if (okay) {
          return resolve(xhr.response);
        }
        xhr.response.text().then(text => reject(JSON.parse(text != '' ? text : null)));
      };

      // Mimic { 'error': 'You need to sign in or sign up before continuing.' }
      xhr.onerror = () => reject({ error: 'Could not connect to server' });
      xhr.onabort = () => reject({ error: 'Request aborted' });
      xhr.ontimeout = () => reject({ error: 'Request timed out' });
    });
  }
}

export default Attachment;
