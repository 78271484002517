import PropTypes from 'prop-types';
import './Status.scss';

const Status = ({ status }) => {
  const jsonToString = [
    { json: 'active', string: 'Active' },
    { json: 'pending', string: 'Pending' },
    { json: 'delivered', string: 'Delivered' },
    { json: 'cancelled', string: 'Cancelled' },
    { json: 'none', string: 'N/A' },
  ];

  return (
    <div className={`status_component ${status}`}>
      {jsonToString.find(x => x.json == status).string}
    </div>
  );
};

Status.propTypes = {
  status: PropTypes.string,
};

export default Status;
