import PropTypes from 'prop-types';
import DeliveryStop from '../../DeliveryStop/DeliveryStop';
import './Schedule.scss';

const Schedule = ({ schedule }) => {
  const empty = schedule.map(stop => {
    if (
      stop.address == '-' &&
      stop.estimate.date == '---' &&
      stop.estimate.time == '-:-' &&
      stop.actual.date == '---' &&
      stop.actual.time == '-:-'
    ) {
      return true;
    } else {
      return false;
    }
  });

  if (empty.every(bool => bool == true)) {
    return (
      <div className="no_schedule">
        <em>No schedule details found</em>
      </div>
    );
  }
  return (
    <div className="schedule_container">
      <div className="schedule">
        {schedule.map(stop => {
          if (
            stop.address != '-' ||
            stop.estimate.date != '---' ||
            stop.estimate.time != '-:-' ||
            stop.actual.date != '---' ||
            stop.actual.time != '-:-'
          ) {
            return (
              <DeliveryStop
                key={stop.stop}
                stop={stop.stop}
                entity={stop.entity}
                address={stop.address}
                estimate={stop.estimate}
                actual={stop.actual}
              />
            );
          }
        })}
      </div>
    </div>
  );
};
Schedule.propTypes = {
  schedule: PropTypes.array,
};

export default Schedule;
