import PropTypes from 'prop-types';
import './Sidebar.scss';
import { useParams } from 'react-router-dom';
import Status from '../../Status/Status';
import { useEffect, useState } from 'react';
import Attachment from '../../../api/controllers/Attachment/Attachment';
import Attachments from '../../Attachments/Attachments';

const Sidebar = ({ sidebarDetails, setError }) => {
  const {
    reference,
    customerReference,
    status,
    controller,
    bookingDate,
    collectionDate,
    deliveryDate,
  } = sidebarDetails;
  const [attachments, setAttachments] = useState([]);

  const params = useParams();

  const apiParams = {
    parentType: 'Job',
    parentId: params.id,
  };

  const getAttachments = () => {
    Attachment.all(apiParams)
      .then(json => {
        setAttachments(json.items);
      })
      .catch(e => {
        setError(e.error);
      });
  };

  useEffect(() => {
    if (window.navigator.onLine) {
      getAttachments();
    } else {
      setError('No connection available');
    }
  }, []);

  return (
    <div className="sidebar">
      <div className="name_status_container">
        <div className="job_name">
          <p className="job">Job</p>
          <p className="reference">{` ${reference}`}</p>
        </div>
        <div className="job_status">
          <p className="label">STATUS</p>
          <Status status={status} />
        </div>
      </div>
      <div className="about_container">
        <p className="about">About</p>
        <div className="info_row">
          <p className="label">YOUR REFERENCE</p>
          <p className="info">{customerReference}</p>
        </div>
        <div className="info_row">
          <p className="label">OUR REFERENCE</p>
          <p className="info">{reference}</p>
        </div>
        <div className="info_row">
          <p className="label">CONTROLLER</p>
          <p className="info">{controller}</p>
        </div>
        <div className="info_row">
          <p className="label">BOOKED</p>
          <p className="info">{bookingDate}</p>
        </div>
        <div className="info_row">
          <p className="label">COLLECTION DATE</p>
          <p className="info">{collectionDate}</p>
        </div>
        <div className="info_row">
          <p className="label">DELIVERY DATE</p>
          <p className="info">{deliveryDate}</p>
        </div>
      </div>
      <div className="attachment_container">
        <p className="heading">Attachments</p>
        <Attachments attachments={attachments} setError={setError} />
      </div>
    </div>
  );
};
Sidebar.propTypes = {
  sidebarDetails: PropTypes.object,
  setError: PropTypes.func,
};

export default Sidebar;
