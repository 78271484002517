import apiCall from '../../apiCall/apiCall';
import buildUrlSearchParams from '../../apiCall/buildUrlSearchParams/buildUrlSearchParams';
// This class handles any API calls related to (Third Party!) Jobs.
class Job {
  static all(data) {
    const params = buildUrlSearchParams(data);
    return apiCall('GET', 'api/third_parties/jobs', params);
  }

  static show(id) {
    return apiCall('GET', `api/third_parties/jobs/${id}`);
  }
}

export default Job;
