import './Dropdown.scss';
import PropTypes from 'prop-types';
import { useEffect, useRef, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAngleDown, faAngleUp } from '@fortawesome/free-solid-svg-icons';

// General Dropdown Component
const Dropdown = ({ data, selected, setSelected, disabled }) => {
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const ref = useRef();

  useEffect(() => {
    const checkIfClickedOutside = e => {
      if (dropdownOpen && ref.current && !ref.current.contains(e.target)) {
        setDropdownOpen(false);
      }
    };
    document.addEventListener('mousedown', checkIfClickedOutside);
    return () => {
      document.removeEventListener('mousedown', checkIfClickedOutside);
    };
  }, [dropdownOpen]);

  return (
    <div className="dropdown_component" ref={ref}>
      <div
        data-testid="dropdown_box"
        className={`select_box ${dropdownOpen ? 'active' : ''} ${disabled ? 'disabled' : ''}`}
        onClick={() => {
          if (!disabled) {
            setDropdownOpen(!dropdownOpen);
          }
        }}
      >
        <p className="item">{selected}</p>
        <FontAwesomeIcon icon={dropdownOpen ? faAngleUp : faAngleDown} className="arrow" />
      </div>

      {dropdownOpen && (
        <div className="options_list">
          {data.map((item, index) => (
            <div
              className="item"
              key={index}
              onClick={() => {
                setSelected(item);
                setDropdownOpen(false);
              }}
            >
              {item.text}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

Dropdown.propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  selected: PropTypes.string,
  setSelected: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Dropdown;
