import { useEffect } from 'react';
import './App.scss';
import useToken from '../hooks/useToken/useToken';
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom';
import Navbar from '../common/Navbar/Navbar';
import Login from '../pages/Login/Login';
import DisplayJob from '../pages/DisplayJob/DisplayJob';
import Home from '../pages/Home/Home';

const App = () => {
  const { token, setToken } = useToken();

  const checkToken = e => {
    if (e.key === 'token') setToken(e.newValue);
  };

  const handleTokenChangeEvent = e => {
    if (e.detail) localStorage.setItem('logout-message', JSON.stringify(e.detail));
  };

  /*
   * Handles when another tab clears the token
   */
  useEffect(() => {
    window.addEventListener('storage', checkToken);
    window.addEventListener('token-change-message', handleTokenChangeEvent);

    return () => {
      window.removeEventListener('storage', checkToken);
      window.removeEventListener('token-change-message', handleTokenChangeEvent);
    };
  }, []);

  return (
    <BrowserRouter>
      <Routes>
        {!token && <Route path="login" element={<Login />} />}
        {token && (
          <Route element={<Navbar />}>
            <Route path="/">
              <Route index element={<Home />} />
              <Route path="/jobs/:id" element={<DisplayJob />} />
            </Route>
          </Route>
        )}
        <Route
          path="*"
          element={<Navigate to={token ? '/' : '/login'} state={location.pathname} replace />}
        />
      </Routes>
    </BrowserRouter>
  );
};

export default App;
