import apiCall from '../../apiCall/apiCall';
// This class handles any API calls related to account management.
class Account {
  static logIn(email, password) {
    const data = { login: email, password: password };
    return apiCall('POST', 'users/sign_in.json', { user: data });
  }

  static logOut() {
    return apiCall('DELETE', 'users/sign_out.json');
  }

  static current() {
    return apiCall('GET', 'api/users/current.json');
  }
}

export default Account;
