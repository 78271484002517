import PageNumbers from '../../common/PageNumbers/PageNumbers';

class tableHelper {
  static onSort(header, sort, setSort) {
    if (!header.sortingAttribute) return;
    const headerAttr = header.sortingAttribute;
    if (!sort || sort.sort !== headerAttr) return setSort({ sort: headerAttr, order: 'asc' });
    if (sort.sort === headerAttr && sort.order === 'desc')
      return setSort({ sort: headerAttr, order: 'asc' });
    setSort({ sort: headerAttr, order: 'desc' });
  }

  static genPageNumbers(currentPage, setCurrentPage, pageTotal) {
    return (
      <PageNumbers
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        pageTotal={pageTotal}
      />
    );
  }

  static formatAddress = address => {
    //address: string[]
    return address.filter(Boolean).join(', ');
  };

  static paginationText(upperBound, lowerBound, total) {
    return `Showing ${upperBound <= 0 ? 0 : lowerBound} to ${
      upperBound <= 0 ? 0 : upperBound
    } of ${total}`;
  }
}

export default tableHelper;
