import './Input.scss';
import PropTypes from 'prop-types';
import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash, faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';

// General Input Component
const Input = ({ label, type, autofocus, placeholder, setValue, value, disabled }) => {
  const [showPass, setShowPass] = useState(false);

  return (
    <div className={`input_component ${type}`}>
      {label && <label className={disabled ? 'disabled' : ''}>{label}</label>}
      <div className="inner_input">
        <input
          type={type == 'password' && !showPass ? 'password' : ''}
          data-testid={`input_${label}`}
          autoCapitalize="off"
          autoFocus={autofocus}
          disabled={disabled}
          placeholder={placeholder}
          value={value}
          onChange={e => setValue(e.target.value)}
        />
        {type == 'search' && (
          <FontAwesomeIcon className="magnifying_glass" icon={faMagnifyingGlass} />
        )}
        {type == 'password' && !disabled && value && (
          <button
            type="button"
            className="show_password"
            data-testid="show_password_button"
            onClick={() => {
              setShowPass(!showPass);
            }}
          >
            <FontAwesomeIcon className="eye" icon={showPass ? faEyeSlash : faEye} />
          </button>
        )}
      </div>
    </div>
  );
};

Input.propTypes = {
  label: PropTypes.string,
  type: PropTypes.string,
  autofocus: PropTypes.bool,
  placeholder: PropTypes.string,
  value: PropTypes.string,
  setValue: PropTypes.func,
  disabled: PropTypes.bool,
};

export default Input;
