import PropTypes from 'prop-types';
import { faCalendar, faClock } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './DeliveryStop.scss';
import { ReactComponent as Calendar } from '../../assets/calendar.svg';
import { ReactComponent as Hub } from '../../assets/hub.svg';
import { ReactComponent as Loading } from '../../assets/loading.svg';
import { ReactComponent as Truck } from '../../assets/truck.svg';

const DeliveryStop = ({ stop, entity, address, estimate, actual }) => {
  const stopValues = [
    { heading: 'Collection', icon: <Truck /> },
    { heading: 'Arrival At Origin Hub', icon: <Hub /> },
    { heading: 'Loading', icon: <Loading /> },
    { heading: 'Shipping', icon: <Truck /> },
    { heading: 'Arrival At Dest. Hub', icon: <Hub /> },
    { heading: 'Depart From Dest. Hub', icon: <Truck /> },
    { heading: 'Delivery', icon: <Calendar /> },
  ];

  return (
    <div className="stop_container">
      {stop > 0 && <div className="timeline top" />}{' '}
      <div className="icon">{stopValues[stop]?.icon}</div>
      {stop < 6 && <div className="timeline bottom" />}
      <div className="stop">
        <p className={`heading ${stopValues[stop]?.heading}`}>{stopValues[stop]?.heading}</p>
        <div className="stop_info">
          <div className="stop_entity_address">
            <p className="info entity">{entity}</p>
            <p className="info address">{address}</p>
          </div>
          <div className="estimated_actual">
            <div className="time_block">
              <p className="label">ESTIMATED</p>
              <div className="date_and_time">
                <FontAwesomeIcon icon={faCalendar} className="calendar" />
                <p className="info">{estimate.date}</p>
                <FontAwesomeIcon icon={faClock} className="clock" />
                <p className="info">{estimate.time}</p>
              </div>
            </div>
            <div className="time_block">
              <p className="label">ACTUAL</p>
              <div className="date_and_time">
                <FontAwesomeIcon icon={faCalendar} className="calendar" />
                <p className="info">{actual.date}</p>
                <FontAwesomeIcon icon={faClock} className="clock" />
                <p className="info">{actual.time}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
DeliveryStop.propTypes = {
  stop: PropTypes.number,
  entity: PropTypes.string,
  address: PropTypes.string,
  estimate: PropTypes.object,
  actual: PropTypes.object,
};

export default DeliveryStop;
