import PropTypes from 'prop-types';
import './Overview.scss';
import { ReactComponent as InfoIcon } from '../../../assets/info-icon.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCircle } from '@fortawesome/free-solid-svg-icons';

const Overview = ({ entities, consignment, dangerousGoods, dimensions }) => {
  return (
    <div className="overview_container">
      <div className="container">
        <div className="heading">Entities & Addressing</div>
        <div className="entities_info_container">
          <div className="info_column">
            <p className="label">
              SHIPPER
              <InfoIcon className="info_icon" />
              <div className="address_hover">
                <p className="header">Main address</p>
                <p className="info">{entities.shipperMainAddress}</p>
              </div>
            </p>
            <p className="info">{entities.shipperName}</p>
          </div>
          <div className="info_column">
            <p className="label">COLLECT FROM</p>
            <p className="info">{entities.collectFrom}</p>
          </div>
          <div className="info_column">
            <p className="label">
              CONSIGNEE
              <InfoIcon className="info_icon" />
              <div className="address_hover">
                <p className="header">Main address</p>
                <p className="info">{entities.consigneeMainAddress}</p>
              </div>
            </p>
            <p className="info">{entities.consigneeName}</p>
          </div>

          <div className="info_column">
            <p className="label">DELIVER TO</p>
            <p className="info">{entities.deliverTo}</p>
          </div>
          <div className="info_column">
            <p className="label">INCOTERM</p>
            <p className="info">{entities.incoterm}</p>
          </div>
          <div className="info_column">
            <p className="label">NAMED PLACE</p>
            <p className="info">{entities.incotermPlace}</p>
          </div>
        </div>
      </div>
      <div className="container">
        <div className="heading">Consignment Details</div>
        <div className="consignment_info_container">
          <div className="info_column">
            <p className="label">QUANTITY</p>
            <p className="info">{consignment.quantity}</p>
          </div>
          <div className="info_column">
            <p className="label">PACKAGE TYPE</p>
            <p className="info">{consignment.packageType}</p>
          </div>
          <div className="info_column">
            <p className="label">GROSS WEIGHT</p>
            <p className="info">{consignment.grossWeight}Kg</p>
          </div>
          <div className="info_column">
            <p className="label">NET WEIGHT</p>
            <p className="info">{consignment.netWeight}Kg</p>
          </div>
          <div className="info_column">
            <p className="label">CUBIC METRES</p>
            <p className="info">
              {consignment.cubicMetres}m<sup>3</sup>
            </p>
          </div>
          <div className="info_column">
            <p className="label">LOADING METRES</p>
            <p className="info">{consignment.loadingMetres}m</p>
          </div>
        </div>
        <div className="info_column description">
          <p className="label">DESCRIPTION</p>
          <p className="info">{consignment.description}</p>
        </div>
      </div>
      {dangerousGoods.length > 0 && (
        <div className="container">
          <div className="heading">Dangerous Goods Details</div>
          <div className="dangerous_goods_info_container">
            {dangerousGoods.map((good, index) => (
              <div className="option_row" key={index}>
                <FontAwesomeIcon className="icon" icon={faCircle} />
                <p className="goods">{good}</p>
              </div>
            ))}
          </div>
        </div>
      )}
      {dimensions.length > 0 && (
        <div className="container">
          <div className="heading">Package Dimensions</div>
          <div className="package_dimensions_info_container">
            <div className="table_headers">
              <p className="header first long">QUANTITY</p>
              <p className="header first short">#</p>
              <p className="header">PACKAGE TYPE</p>
              <p className="header">LENGTH</p>
              <p className="header">WIDTH</p>
              <p className="header">HEIGHT</p>
            </div>
            <div className="table_body">
              {dimensions.map((dimension, index) => {
                return (
                  <div className={`row ${index % 2 == 0 ? 'coloured' : ''}`} key={index}>
                    <p className="item first">{dimension.quantity}</p>
                    <p className="item">{dimension.type}</p>
                    <p className="item">{dimension.length}cm</p>
                    <p className="item">{dimension.width}cm</p>
                    <p className="item">{dimension.height}cm</p>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
      )}
    </div>
  );
};
Overview.propTypes = {
  entities: PropTypes.object,
  consignment: PropTypes.object,
  dangerousGoods: PropTypes.array,
  dimensions: PropTypes.array,
};

export default Overview;
