// import PropTypes from 'prop-types';

import { faSpinner } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './LoadingSpinner.scss';

const LoadingSpinner = () => {
  return (
    <div className="loading_container">
      <FontAwesomeIcon className="spinner" icon={faSpinner} spinPulse />
      Loading...
    </div>
  );
};

LoadingSpinner.propTypes = {};

export default LoadingSpinner;
