import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import TablePresenter from '../TablePresenter/TablePresenter';
import Job from '../../api/controllers/Job/Job';
import tableHelper from '../../utils/tableHelper/tableHelper';
import { formatDate } from '../../utils/dateHelper/dateHelper';
import { Link } from 'react-router-dom';
import Status from '../Status/Status';

const Table = ({
  filterObj,
  limit = 10,
  defaultSort = { sort: 'bookingDate', order: 'asc' },
  searchText,
  noEntity,
  setError,
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [sort, setSort] = useState(defaultSort);
  const [data, setData] = useState({ total: 0, items: [] });
  const [loading, setLoading] = useState(true);

  const headers = [
    { text: 'BOOKED', sortingAttribute: 'bookingDate', className: 'booked' },
    { text: 'OUR REFERENCE', sortingAttribute: 'jobReference', className: 'our_ref' },
    { text: 'YOUR REFERENCE', sortingAttribute: 'customerReference', className: 'your_ref' },
    { text: 'SHIPPER', sortingAttribute: 'shipper', className: 'shipper' },
    {
      text: 'COLLECTION',
      sortingAttribute: 'collectionAddress',
      className: 'collection',
    },
    { text: 'CONSIGNEE', sortingAttribute: 'consignee', className: 'consignee' },
    { text: 'DELIVERY', sortingAttribute: 'deliveryAddress', className: 'delivery' },
    { text: 'STATUS', className: 'status' },
  ];

  const params = {
    filter: searchText,
    limit,
    offset: limit * (currentPage - 1),
    ...sort,
    ...filterObj,
  };

  const getJobs = type => {
    if (type != 'page' && currentPage != 1) {
      setCurrentPage(1);
    } else {
      Job.all(params)
        .then(json => {
          setData({
            total: json.total,
            items: json.items,
          });
          setLoading(false);
        })
        .catch(e => {
          setError(e.error);
          setLoading(false);
        });
    }
  };

  const rowCells = (item, i) => {
    const bookingDate = item.bookingDate ? formatDate(item.bookingDate) : '-';
    const ourRef = item.jobReference ? item.jobReference : '-';
    const yourRef = item.customerReference ? item.customerReference : '-';
    const shipperName = item.shipper?.displayName ? item.shipper?.displayName : '-';
    const shipAddress = item.collectionAddress
      ? tableHelper.formatAddress([
          item.collectionAddress?.region,
          item.collectionAddress?.country?.countryCode,
        ])
      : '-';
    const consigneeName = item.consignee?.displayName ? item.consignee?.displayName : '-';
    const consigneeAddress = item.deliveryAddress
      ? tableHelper.formatAddress([
          item.deliveryAddress?.region,
          item.deliveryAddress?.country?.countryCode,
        ])
      : '-';
    const status = item.status ? item.status : 'none';

    return (
      <tr key={i} className={i % 2 === 1 ? '' : 'coloured'}>
        <td className="booking">{bookingDate}</td>
        <td className="our_ref">
          <Link to={`/jobs/${item.id}`}>{ourRef}</Link>
        </td>
        <td className="your_ref">{yourRef}</td>
        <td className="shipper">
          <div className="text">{shipperName}</div>
        </td>
        <td className="ship_address">
          <div className="text">{shipAddress}</div>
        </td>
        <td className="consignee">
          <div className="text">{consigneeName}</div>
        </td>
        <td className="delivery_address">
          <div className="text">{consigneeAddress}</div>
        </td>
        <td className="status">
          <Status status={status} />
        </td>
      </tr>
    );
  };

  const pageNum = data && Math.ceil(data.total / limit);

  useEffect(() => {
    setLoading(true);
    const getData = setTimeout(() => {
      getJobs('search');
    }, 2000);

    return () => clearTimeout(getData);
  }, [searchText, filterObj]);

  useEffect(() => {
    setLoading(true);
    getJobs('sort');
  }, [sort]);

  useEffect(() => {
    setLoading(true);
    getJobs('page');
  }, [currentPage]);

  const lowerBound = (currentPage - 1) * limit + 1;
  const upperBound = currentPage * limit + (data.items.length - limit);

  return (
    <TablePresenter
      headers={headers}
      rows={data.items}
      onDrawRow={rowCells}
      sort={sort}
      onSort={header => tableHelper.onSort(header, sort, setSort)}
      pageElements={() => tableHelper.genPageNumbers(currentPage, setCurrentPage, pageNum)}
      pageText={tableHelper.paginationText(upperBound, lowerBound, data.total)}
      noEntity={noEntity}
      loading={loading}
    />
  );
};

Table.propTypes = {
  filterObj: PropTypes.object,
  limit: PropTypes.number,
  defaultSort: PropTypes.object,
  searchText: PropTypes.string,
  noEntity: PropTypes.bool,
  setError: PropTypes.func,
};

export default Table;
