import './Home.scss';
import Account from '../../api/controllers/Account/Account';
import { useEffect, useState } from 'react';
import Error from '../../common/Error/Error';
import Input from '../../common/Input/Input';
import Dropdown from '../../common/Dropdown/Dropdown';
import Table from '../../common/Table/Table';

const Home = () => {
  const [user, setUser] = useState({ entity: '' });
  const [error, setError] = useState('');
  const [searchTerm, setSearchTerm] = useState('');

  const filters = [
    { text: 'Everything', filter: '' },
    { text: 'Active', filter: 'active' },
    { text: 'Pending', filter: 'pending' },
    { text: 'Delivered', filter: 'delivered' },
    { text: 'Cancelled', filter: 'cancelled' },
  ];
  const [currentFilter, setCurrentFilter] = useState({ text: 'Active', filter: 'active' });

  const getCurrent = () => {
    Account.current()
      .then(json => {
        setUser({
          entity: json.entity ? json.entity.displayName : '',
        });
      })
      .catch(e => {
        setError(e.error);
      });
  };

  useEffect(() => {
    if (window.navigator.onLine) {
      getCurrent();
    } else {
      setError('No connection available');
    }
  }, []);

  return (
    <div className="home">
      <div className="jobs">
        <div className="header_background">
          <div className="search_bar">
            {error && (
              <div className="error_container">
                <Error errorMessage={error} />
              </div>
            )}
            <div className="entity_display">
              <p className="jobs_for">{`Jobs ${user.entity && 'for'}`}</p>
              <p className="entity">{user.entity}</p>
            </div>
            <div className="search_filter">
              <div className="search_container">
                <Input
                  type="search"
                  value={searchTerm}
                  setValue={setSearchTerm}
                  placeholder="Search"
                  disabled={user.entity == ''}
                />
              </div>
              <div className="filter_container">
                <Dropdown
                  data={filters}
                  selected={currentFilter.text}
                  setSelected={setCurrentFilter}
                  disabled={user.entity == ''}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="table_content">
          <Table
            searchText={searchTerm}
            filterObj={{ status: currentFilter.filter }}
            noEntity={user.entity == ''}
            setError={setError}
          />
        </div>
      </div>
    </div>
  );
};

export default Home;
