import PropTypes from 'prop-types';
import './Attachments.scss';
import Attachment from '../../api/controllers/Attachment/Attachment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileExcel, faFileImage, faFilePdf } from '@fortawesome/free-regular-svg-icons';

// General Attachments Component
const Attachments = ({ setError, attachments }) => {
  const handleDownload = item => {
    const converter = new FileReader();

    converter.onloadend = event => {
      const a = document.createElement('a');
      a.download = item.attachmentFileName;
      a.href = event.target.result;
      a.click();
    };

    Attachment.download(item.id)
      .then(blob => {
        converter.readAsDataURL(blob);
      })
      .catch(e => {
        if (e) {
          setError(e.error);
        } else {
          setError('Failed to download file');
        }
      });
  };

  const getIcon = contentType => {
    if (contentType.includes('image')) {
      return <FontAwesomeIcon data-testid="image_icon" className="icon" icon={faFileImage} />;
    }
    if (contentType.includes('pdf')) {
      return <FontAwesomeIcon data-testid="pdf_icon" className="icon" icon={faFilePdf} />;
    }
    return <FontAwesomeIcon data-testid="other_icon" className="icon" icon={faFileExcel} />;
  };

  if (attachments?.length == 0) {
    return (
      <div className="attachments">
        <em>No attachments found</em>
      </div>
    );
  }

  return (
    <div className="attachments">
      {attachments?.map((attachment, index) => {
        return (
          <div className="attachment_row" key={index}>
            {getIcon(attachment.attachmentContentType)}
            <p className="download" onClick={() => handleDownload(attachment)}>
              {attachment.attachmentFileName}
            </p>
            <p className="size">{`(${Math.ceil(
              Number(attachment.attachmentFileSize) / 1024,
            )} KB)`}</p>
            {attachment.customs && <div className="customs">{attachment.customs}</div>}
          </div>
        );
      })}
    </div>
  );
};

Attachments.propTypes = {
  setError: PropTypes.func,
  attachments: PropTypes.array,
};

export default Attachments;
