import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import './DisplayJob.scss';
import Button from '../../common/Button/Button';
import Error from '../../common/Error/Error';
import Job from '../../api/controllers/Job/Job';
import Sidebar from '../../common/JobDisplay/Sidebar/Sidebar';
import { formatDate } from '../../utils/dateHelper/dateHelper';
import tableHelper from '../../utils/tableHelper/tableHelper';
import Overview from '../../common/JobDisplay/Overview/Overview';
import Details from '../../common/JobDisplay/Details/Details';
import Schedule from '../../common/JobDisplay/Schedule/Schedule';

const DisplayJob = () => {
  const params = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState('');
  const [cancelled, setCancelled] = useState('');
  const [currentTab, setCurrentTab] = useState(1);
  const [job, setJob] = useState({});

  const getAddress = address => {
    return tableHelper.formatAddress([
      address.addressLine1,
      address.addressLine2,
      address.addressLine3,
      address.addressLine4,
      address.region,
      address.postcode?.name,
      address.country?.countryCode,
    ]);
  };

  const to2DP = num => {
    return new Intl.NumberFormat('en-GB', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }).format(num);
  };

  const sidebarDetails = {
    reference: job?.jobReference ? job.jobReference : '-',
    customerReference: job?.customerReference ? job.customerReference : '-',
    status: job?.status ? job.status : 'none',
    controller: job?.jobController
      ? `${job?.jobController?.firstName} ${job?.jobController?.lastName}`
      : '-',
    bookingDate: job?.bookingDate ? formatDate(new Date(job.bookingDate)) : '-',
    collectionDate: job?.actualCollectionLoadDate
      ? formatDate(new Date(job.actualCollectionLoadDate))
      : '-',
    deliveryDate: job?.actualDeliveryOffloadDate
      ? formatDate(new Date(job.actualDeliveryOffloadDate))
      : '-',
  };

  const overviewEntitiesInfo = {
    shipperName: job?.shipper?.displayName ? job.shipper.displayName : '-',
    shipperMainAddress: job?.shipper?.mainAddress ? getAddress(job.shipper.mainAddress) : '-',
    consigneeName: job?.consignee?.displayName ? job.consignee.displayName : '-',
    consigneeMainAddress: job?.consignee?.mainAddress ? getAddress(job.consignee.mainAddress) : '-',
    collectFrom: job?.collectionAddress ? getAddress(job.collectionAddress) : '-',
    deliverTo: job?.deliveryAddress ? getAddress(job.deliveryAddress) : '-',
    incoterm: job?.incoterm?.incoterm
      ? `${job.incoterm.incoterm}${
          job.incotermVariant ? ` (${job?.incotermVariant.replace(/_/g, ' ')})` : ''
        }`
      : '-',
    incotermPlace: job?.incotermNamedPlace ? job.incotermNamedPlace : '-',
  };

  const overviewConsignmentDetails = {
    quantity: job?.quantity ? job.quantity : '-',
    packageType: job?.packageType?.description ? job.packageType.description : '-',
    netWeight: job?.netWeightKg ? job.netWeightKg : '-',
    grossWeight: job?.grossWeightKg ? job.grossWeightKg : '-',
    cubicMetres: job?.cubicMetres ? to2DP(job.cubicMetres) : '-',
    loadingMetres: job?.loadingMetres ? job.loadingMetres : '-',
    description:
      job?.descriptionLine1 ||
      job?.descriptionLine2 ||
      job?.descriptionLine3 ||
      job?.descriptionLine4
        ? [
            job.descriptionLine1,
            job.descriptionLine2,
            job.descriptionLine3,
            job.descriptionLine4,
          ].join(' ')
        : '-',
  };

  const consignmentOptions = [
    { text: 'Do not stack', bool: job?.doNotStack },
    { text: 'Do not transship', bool: job?.doNotTransship },
    { text: 'Do not part load', bool: job?.doNotPartLoad },
    { text: 'Do not break down', bool: job?.doNotBreakDown },
    { text: 'Tail lift on collection', bool: job?.tailLiftOnCollection },
    { text: 'Tail lift on delivery', bool: job?.tailLiftOnDelivery },
    { text: 'Overhead load', bool: job?.overheadLoad },
    { text: 'Overhead unload', bool: job?.overheadUnload },
    { text: 'POD Required', bool: job?.podRequired },
    { text: 'Full load', bool: job?.fullLoad },
    { text: 'Waste', bool: job?.commodityCategory?.waste },
    { text: 'Foodstuffs', bool: job?.commodityCategory?.foodstuffs },
  ];

  const specialInstructionsDetails = {
    temperatureMin: job?.temperatureMin ? job.temperatureMin : '-',
    temperatureMax: job?.temperatureMax ? job.temperatureMax : '-',
    insurance: job?.insurance
      ? `${job.insurance?.currency?.currencySymbol || ''}${to2DP(job.insurance?.value)}`
      : '-',
    cashOnDeliveryValue: job?.cashOnDelivery?.value
      ? `${job.cashOnDelivery?.currency?.currencySymbol || ''}${to2DP(job.cashOnDelivery?.value)}`
      : '-',
    cashOnDeliveryReceived: job?.cashOnDelivery?.received ? 'YES' : 'NO',
    cashOnDeliveryDescription: job?.cashOnDelivery?.details ? job.cashOnDelivery.details : '-',
  };

  const dimensions = job?.dimensionsLines
    ? job.dimensionsLines.map(dimension => {
        return {
          quantity: dimension?.quantity,
          type: dimension?.packageType?.description,
          length: dimension?.lengthCm,
          width: dimension?.widthCm,
          height: dimension?.heightCm,
        };
      })
    : [];

  const dangerousGoods = job?.dangerousGoodsLines
    ? job?.dangerousGoodsLines.map(goods => {
        return goods.longText;
      })
    : [];

  const scheduleDetails = [
    {
      stop: 0,
      entity: job?.collectionAddress?.entity ? job?.collectionAddress?.entity.displayName : '-',
      address: job?.collectionAddress ? getAddress(job.collectionAddress) : '-',
      estimate: {
        date: job?.estimatedCollectionLoadDate
          ? formatDate(new Date(job.estimatedCollectionLoadDate))
          : '---',
        time: job?.estimatedCollectionLoadTime ? job.estimatedCollectionLoadTime : '-:-',
      },
      actual: {
        date: job?.actualCollectionLoadDate
          ? formatDate(new Date(job.actualCollectionLoadDate))
          : '---',
        time: job?.actualCollectionLoadTime ? job.actualCollectionLoadTime : '-:-',
      },
    },
    {
      stop: 1,
      entity: job?.originHub?.address?.entity ? job?.originHub?.address?.entity.displayName : '-',
      address: job?.originHub?.address ? getAddress(job.originHub.address) : '-',
      estimate: {
        date: job?.estimatedCollectionOffloadDate
          ? formatDate(new Date(job.estimatedCollectionOffloadDate))
          : '---',
        time: job?.estimatedCollectionOffloadTime ? job.estimatedCollectionOffloadTime : '-:-',
      },
      actual: {
        date: job?.actualCollectionOffloadDate
          ? formatDate(new Date(job.actualCollectionOffloadDate))
          : '---',
        time: job?.actualCollectionOffloadTime ? job.actualCollectionOffloadTime : '-:-',
      },
    },
    {
      stop: 2,
      entity: job?.originHub?.address?.entity ? job?.originHub?.address?.entity.displayName : '-',
      address: job?.originHub?.address ? getAddress(job.originHub.address) : '-',
      estimate: {
        date: job?.estimatedLoadingDate ? formatDate(new Date(job.estimatedLoadingDate)) : '---',
        time: job?.estimatedLoadingTime ? job.estimatedLoadingTime : '-:-',
      },
      actual: {
        date: job?.actualLoadingDate ? formatDate(new Date(job.actualLoadingDate)) : '---',
        time: job?.actualLoadingTime ? job.actualLoadingTime : '-:-',
      },
    },
    {
      stop: 3,
      entity: job?.originHub?.address?.entity ? job?.originHub?.address?.entity.displayName : '-',
      address: job?.originHub?.address ? getAddress(job.originHub.address) : '-',
      estimate: {
        date: job?.estimatedShippingDate ? formatDate(new Date(job.estimatedShippingDate)) : '---',
        time: job?.estimatedShippingTime ? job.estimatedShippingTime : '-:-',
      },
      actual: {
        date: job?.actualShippingDate ? formatDate(new Date(job.actualShippingDate)) : '---',
        time: job?.actualShippingTime ? job.actualShippingTime : '-:-',
      },
    },
    {
      stop: 4,
      entity: job?.destinationHub?.address?.entity
        ? job?.destinationHub?.address?.entity.displayName
        : '-',
      address: job?.destinationHub?.address ? getAddress(job.destinationHub.address) : '-',
      estimate: {
        date: job?.estimatedArrivalDate ? formatDate(new Date(job.estimatedArrivalDate)) : '---',
        time: job?.estimatedArrivalTime ? job.estimatedArrivalTime : '-:-',
      },
      actual: {
        date: job?.actualArrivalDate ? formatDate(new Date(job.actualArrivalDate)) : '---',
        time: job?.actualArrivalTime ? job.actualArrivalTime : '-:-',
      },
    },
    {
      stop: 5,
      entity: job?.destinationHub?.address?.entity
        ? job?.destinationHub?.address?.entity.displayName
        : '-',
      address: job?.destinationHub?.address ? getAddress(job.destinationHub.address) : '-',
      estimate: {
        date: job?.estimatedDeliveryLoadDate
          ? formatDate(new Date(job.estimatedDeliveryLoadDate))
          : '---',
        time: job?.estimatedDeliveryLoadTime ? job.estimatedDeliveryLoadTime : '-:-',
      },
      actual: {
        date: job?.actualDeliveryLoadDate
          ? formatDate(new Date(job.actualDeliveryLoadDate))
          : '---',
        time: job?.actualDeliveryLoadTime ? job.actualDeliveryLoadTime : '-:-',
      },
    },
    {
      stop: 6,
      entity: job?.deliveryAddress?.entity ? job?.deliveryAddress?.entity.displayName : '-',
      address: job?.deliveryAddress ? getAddress(job.deliveryAddress) : '-',
      estimate: {
        date: job?.estimatedDeliveryOffloadDate
          ? formatDate(new Date(job.estimatedDeliveryOffloadDate))
          : '---',
        time: job?.estimatedDeliveryOffloadTime ? job.estimatedDeliveryOffloadTime : '-:-',
      },
      actual: {
        date: job?.actualDeliveryOffloadDate
          ? formatDate(new Date(job.actualDeliveryOffloadDate))
          : '---',
        time: job?.actualDeliveryOffloadTime ? job.actualDeliveryOffloadTime : '-:-',
      },
    },
  ];

  const getJob = () => {
    Job.show(params.id)
      .then(json => {
        setJob(json);
        if (json.cancellationReason) {
          setCancelled(json.cancellationReason);
        }
      })
      .catch(() => {
        setError('Failed to fetch job');
      });
  };

  useEffect(() => {
    if (window.navigator.onLine) {
      getJob();
    } else {
      setError('No connection available');
    }
  }, []);

  return (
    <div className="display_job">
      <div className="header">
        <div className="back_button">
          <Button
            buttonType="secondary_button xs back"
            label="Back to job list"
            onClick={() => navigate('/')}
          />
        </div>
        {(error || cancelled) && (
          <div className="error_container">
            <Error
              errorMessage={error ? error : cancelled}
              errorType={cancelled ? 'cancelled' : ''}
            />
          </div>
        )}
      </div>
      <div className="job_information">
        <div className="sidebar">
          <Sidebar sidebarDetails={sidebarDetails} setError={setError} />
        </div>
        <div className="info_tabs">
          <div className="tabs">
            <div
              className={`tab ${currentTab == 1 ? 'active_tab' : ''}`}
              onClick={() => setCurrentTab(1)}
            >
              Overview
            </div>
            <div
              className={`tab ${currentTab == 2 ? 'active_tab' : ''}`}
              onClick={() => setCurrentTab(2)}
            >
              Special Instructions
            </div>
            <div
              className={`tab ${currentTab == 3 ? 'active_tab' : ''}`}
              onClick={() => setCurrentTab(3)}
            >
              Schedule
            </div>
          </div>
          {currentTab == 1 && (
            <Overview
              entities={overviewEntitiesInfo}
              consignment={overviewConsignmentDetails}
              dangerousGoods={dangerousGoods}
              dimensions={dimensions}
            />
          )}
          {currentTab == 2 && (
            <Details
              options={consignmentOptions}
              specialInstructions={specialInstructionsDetails}
              dimensions={dimensions}
              dangerousGoods={dangerousGoods}
            />
          )}
          {currentTab == 3 && <Schedule schedule={scheduleDetails} />}
        </div>
      </div>
    </div>
  );
};

export default DisplayJob;
