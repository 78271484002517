import { Outlet, useNavigate } from 'react-router-dom';
import './Navbar.scss';
import useToken from '../../hooks/useToken/useToken';
import { ReactComponent as NavBarLogo } from '../../assets/NavBar-Logo.svg';
import Account from '../../api/controllers/Account/Account';
import { useEffect, useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowRightFromBracket } from '@fortawesome/free-solid-svg-icons';

const Navbar = () => {
  const navigate = useNavigate();
  const { setToken } = useToken();
  const [user, setUser] = useState('');

  const logout = () => {
    Account.logOut().then(() => {
      setToken(null);
      navigate('/');
    });
  };

  const getCurrent = () => {
    Account.current()
      .then(json => {
        setUser(`${json.firstName} ${json.lastName}`);
      })
      .catch(() => logout());
  };

  useEffect(() => {
    getCurrent();
  }, []);

  return (
    <div className="navbar">
      <header>
        <div className="top">
          <div className="left">
            <NavBarLogo className="logo" data-testid="portal_logo" onClick={() => navigate('/')} />

            <FontAwesomeIcon
              data-testid="mobile_logout_symbol"
              icon={faArrowRightFromBracket}
              className="mobile_logout"
              onClick={() => logout()}
            />
          </div>
          <div className="right">
            <div className="user">{`Welcome ${user}`}</div>
            <button className="logout" onClick={() => logout()}>
              Log out
              <FontAwesomeIcon
                data-testid="desktop_logout_symbol"
                icon={faArrowRightFromBracket}
                className="logout_symbol"
              />
            </button>
          </div>
        </div>
        <div className="user below">{`Welcome ${user}`}</div>
      </header>
      <div className="content">
        <Outlet />
      </div>
    </div>
  );
};

export default Navbar;
